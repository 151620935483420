
export class Sound{
    sound: any;
    constructor(src: string) {

        this.sound = document.createElement('audio');
        this.sound.src = src;
        this.sound.setAttribute('preload', 'auto');
        this.sound.setAttribute('controls', 'none');
        this.sound.style.display = 'none';
        document.body.appendChild(this.sound);                
        
    }

    play(): void{
        this.sound.play();
    }

    stop(): void{
        this.sound.pause();
    }
}
