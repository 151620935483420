import {
  Component,
  OnInit,
  Input,
  forwardRef,
  ViewEncapsulation,
} from '@angular/core';
import { Field, DatabaseService, SelectItem, Validator } from '@core/database';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Observable,of } from 'rxjs';



export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutomatedFieldComponent),
  multi: true,
};

export const esito_ispezioni: SelectItem[] = [
  { value: '1', display : 'POSITIVA'},
  { value: '2', display : 'NEGATIVA'},
  { value: '99', display : 'ISPEZIONE MANCATA'},
]


@Component({
  selector: 'automated-field',
  templateUrl: './automated-field.component.html',
  styleUrls: ['./automated-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})


export class AutomatedFieldComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() field: Field;
  @Input() control: FormControl;

  innerValue: any = '';
  isReadOnly = false;
  isRequired = false;
  model: string;
  type = 'input';
  selectValues$: Observable<SelectItem[]>;
  validators: Validator[];
  constructor(private database: DatabaseService) {}



  ngOnInit(): void {
    this.checkReadOnly();
    this.checkModel();
    this.checkType();
    this.checkValidators(); 
  }

  //#region private

  private checkValidators(): void {
    this.validators = this.field.validators;
    if (!this.validators) {
      return;      
    }

    this.isRequired = this.validators.filter( validator => validator.type === 'required').length > 0;
  }

  private checkReadOnly(): void {
    if (!this.field.properties) {
      return;
    }
    const prop = this.field.properties.find(
      (property) => property.type === 'editable'
    );
    if (!prop) {
      return;
    }

    this.isReadOnly = prop.value === 'false';
  }


  getEsitiIspezioniItems(): Observable<SelectItem[]> {
      //console.log(Observable.of(esito_ispezioni));
      return Observable.of(esito_ispezioni);
  }

  private checkType(): void {
    if (!this.field.type) {
      return;
    }

    if (!this.field.type.name) {
      return;
    }
    switch (this.field.type.name) {
      case 'Date':
        this.type = 'datetime-local';
        break;
      case 'Enum':
        if (this.model === 'select') {
          this.loadEnumForSelect(this.field.type.value);
        }
        break;
      case 'Static':
          this.selectValues$ = this.getEsitiIspezioniItems();
          break;
        
    }
  }
/*
  private getValuesForSelect(): Observable<SelectItem[]> {
    let booleans = [
      {
        value : 'SI'
      },
      {
        value : 'NO'
      }
    ]
    return booleans.map((element) => {
      const selectItem: SelectItem = {
        value: element[this.value],
        display: element[this.value],
      };
      return selectItem;

    });
  }
*/

  private loadEnumForSelect(enumType: string): void {
    
    switch (enumType) {
      case 'EnumStati':
        this.selectValues$ = this.database.stati.getValuesForSelect();
        break;
      case 'EnumStatiIspezioni':
        this.selectValues$ = this.database.stati_ispezioni.getValuesForSelect();
        break;
      case 'EnumTipiGeneratori':
        this.selectValues$ = this.database.tipi_generatori.getValuesForSelect();
        break;
      }
  }

  private checkModel(): void {
    // ho creato questa funzione nel caso voglio aumentare le casistiche dei model
    this.model = this.field.type_presentation;
  }
  //#endregion

  // event fired when input value is changed . later propagated up to the form control using the custom value accessor interface
  onChange(e: Event, value: any): void {
    // set changed value
    this.innerValue = value;
    // propagate value into form control using control value accessor interface
    this.propagateChange(this.innerValue);

    // reset errors
    // this.errors = [];
    // //setting, resetting error messages into an array (to loop) and adding the validation messages to show below the field area
    // for (var key in this.c.errors) {
    //   if (this.c.errors.hasOwnProperty(key)) {
    //     if (key === 'required') {
    //       this.errors.push('This field is required');
    //     } else {
    //       this.errors.push(this.c.errors[key]);
    //     }
    //   }
    // }
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  propagateChange = (_: any) => {};

  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}
}
