import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { ModaleClass, ModaleModel } from '../modali-classes';
import { RequestOtpComponent } from './request-otp.component';

@Injectable({
  providedIn: 'root',
})
export class RequestOtpService extends ModaleClass implements ModaleModel {
  public id = 'request-otp';

  constructor(public dialog: MatDialog) {
    super(dialog);
  }

  open(fullIspection: any): Observable<any> {
    return new Observable((observer) => {
      this.openBox(RequestOtpComponent, {
        width: '450px',
        data: {
          observer: observer,
          params: { fullIspection },
          modaliService: this.modaliService,
        },
      });
      this.dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }
}
