import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { NotificheService } from '@core/services/notifiche.service';
import { ApiService } from '@core/services/api.service';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _spinner: LoaderService,
    private notifiche: NotificheService,
    private api: ApiService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  sendRequest(): void {
    this._spinner.show();
    this.api
      .sendEsito('/login/action/sendrecovery', {
        email: this.forgotPasswordForm.value.email,
      })
      .toPromise()
      .then(
        () => {
          this.notifiche.notifica(
            'La richiesta è stata inviata con successo.Controlla la tua mail'
          );
        },
        (error: any) => {
          this.notifiche.errore(error);
        }
      )
      .finally(() => {
        this._spinner.hide();
      });
  }
}
