/**
 * Created by max on 11/03/18.
 */

export class BaseComponent{

    getNumber(total, itemsPerPage): number[] {
        let number = Math.floor(total / itemsPerPage);
        if (total % itemsPerPage !== 0 ) {
            number++;
        }
        return new Array(number);
    }

}
