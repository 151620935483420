import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationNotificationService {

  _data: any;
  onDataChanged: BehaviorSubject<any>;

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private _fuseNavigationService: FuseNavigationService
  ) {

    this.onDataChanged = new BehaviorSubject<any>({});
    this.authService.onChangeCurrentUser
      .subscribe(
        () => {
          if (this.authService.isLogged) {
            this.update();
          }
        }
      );
  }

  update(): Promise<any> {
    return new Promise((resolve) => {
      // this.api.sendEsito('/home/action/getwidgets').toPromise().then(
      //   (data: any) => {
      //     this._data = data;
      //     this.onDataChanged.next(this._data);
      //     // console.log(this._data);
      //     this.updateItem(this._data.helpdesk.pending, 'support');
      //     this.updateItem(this._data.inassistance.pending, 'inassistance');

      //   },
      //   (error: any) => {
      //     console.log('home widgets ', error);
      //   }
      // ).finally(() => resolve());
      resolve();

    });

  }




  updateItem(number: number, id: string): void {
    // console.log(id, number);
    if (number > 0) {
      this._fuseNavigationService.updateNavigationItem(id, {
        badge: {
          title: number,
          hidden: false
        }
      });
    } else {
      this._fuseNavigationService.updateNavigationItem(id, {
        badge: {
          title: '',
          hidden: true
        }
      });
    }

  }

}
