import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject } from 'rxjs';
import { WeatherService } from '@core/services/weather.service';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { ApiService } from '@core/services/api.service';
import { WebsocketService } from '@core/services/websocket.service';
import {
  trigger,
  state,
  transition,
  animate,
  style,
} from '@angular/animations';
import { ActivatedRoute } from '@angular/router';

export class Status {
  current: any | boolean;
  pending: any[];

  constructor() {
    this.current = false;
    this.pending = [];
  }
}

@Component({
  selector: 'smartqueue',
  templateUrl: './smartqueue.component.html',
  styleUrls: ['./smartqueue.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    fuseAnimations,
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(-100%)',
          opacity: 0,
        })
      ),
      transition('open => closed', [animate('0.2s 100ms ease-in')]),
      transition('closed => open', [animate('0.2s 100ms ease-out')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartqueueComponent implements OnInit, OnDestroy {
  @ViewChild('videoBackground', { static: true }) videoplayer: any;
  @ViewChild('audioBackground', { static: true }) audioplayer: any;

  weather: any;
  dateNow = Date.now();
  message_title = 'Makiwin Helpdesk';
  status: Status;
  nextInQueue: boolean;

  private _centre = '0';
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _weatherService: WeatherService,
    private cdr: ChangeDetectorRef,
    private api: ApiService,
    private wsclient: WebsocketService,
    private route: ActivatedRoute
  ) {
    // const centre = this.route.snapshot.queryParams.centre;

    if (this.route.snapshot.queryParams.centre != null) {
      this._centre = this.route.snapshot.queryParams.centre;
    }

    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    this.status = new Status();

    this._unsubscribeAll = new Subject();

    this.weather = false;

    this.update();

    // init websocket subscription
    this.wsclient
      .register('tasks.queue.updated')
      .subscribe(() => this.update());
    this.wsclient.register('tasks.queue.added').subscribe(() => this.update());

    setInterval(() => {
      this.dateNow = Date.now();
      this.cdr.detectChanges();
    }, 1000);
  }

  ngOnInit(): void {
    this._weatherService.onCurrentInfoChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((weather) => {
        this.weather = weather;
      });

    this.fullScreen();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  update(): void {
    this.api
      .sendEsito('/tasks/queue/action/updatemonitor', { centre: this._centre })
      .toPromise()
      .then((ris: any) => {
        const old_current =
          this.status.current !== false ? this.status.current.progressivo : -1;

        this.status.current = ris.current;
        this.status.pending = ris.pending;
        if (ris.current !== false && old_current !== ris.current.progressivo) {
          this.notifyNext();
        }
        this.cdr.detectChanges();
      });
  }

  notifyNext(): void {
    this.nextInQueue = true;
    // this.cdr.markForCheck();
    // const items = document.getElementsByClassName('video');
    // console.log(items);
    try {
      this.audioplayer.nativeElement.play();
      this.videoplayer.nativeElement.play();
    } catch (err) {
      console.log(err);
    }
    // items[0]<any>.play();
    // (any)$('.video').play();
    setTimeout(() => {
      this.nextInQueue = false;
      this.cdr.detectChanges();
      this.videoplayer.nativeElement.pause();
    }, 5000);
  }

  fullScreen(): void {
    const elem = <any>document.documentElement;
    const methodToBeInvoked =
      elem.requestFullscreen ||
      elem.webkitRequestFullScreen ||
      elem['mozRequestFullscreen'] ||
      elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
      console.log(methodToBeInvoked);
      methodToBeInvoked.call(elem);
    }
  }
}
