import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Appointment, DatabaseService } from '@core/database';
import { AppointmentsListService } from '@core/services';
import { AuthService, UserClass } from '@core/services/auth.service';
import {
  FullInspectionSenderService,
  FullInspectionToSend,
} from '@core/services/full-inspection-sender.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomeItem } from './home-item';
import { HomeService } from './home.service';
import { Router } from '@angular/router';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  widgets: any;
  messages: any = [];
  weather: any;
  user: UserClass;
  appointments$: Observable<HomeItem[]> = this._database.appointments
    .selectAll()
    .pipe(map((data) => this.convertListToHome(data)));

  // dateNow = Date.now();
  _dateNow$: BehaviorSubject<number> = new BehaviorSubject<number>(Date.now());
  dateNow$: Observable<number> = this._dateNow$.asObservable();
  private _unsubscribeAll: Subject<any>;

  // Private

  constructor(
    protected sender: FullInspectionSenderService,
    private _fuseSidebarService: FuseSidebarService,
    private _homeService: HomeService,
    private _database: DatabaseService,
    // private _weatherService: WeatherService,
    private _authService: AuthService,
    private router: Router
  ) {
    this.weather = false;
    this._unsubscribeAll = new Subject();

    this.widgets = false;
    setInterval(() => {
      // this.dateNow = Date.now();
      // this._dateNow$.next(Date.now());
    }, 1000);
  }

  ngOnInit(): void {
    this.user = this._authService.currentUserValue;
    this._homeService.fetchAppointment();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  sendInspection(inspection: FullInspectionToSend): void {
    this.sender.sendInspection(inspection);
  }

  editInspection(inspection: FullInspectionToSend, $event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.router.navigate([
      '/appointments/coming',
      inspection.appuntamentoId,
      'compile-full-form',
    ]);
  }

  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  convertListToHome(appointments: Appointment[]): HomeItem[] {
    const list: HomeItem[] = [];
    const tempList: any = {};
    for (const appointment of appointments) {
      const day = appointment.dtime.toString().split('T')[0];
      if (!tempList[day]) {
        tempList[day] = new HomeItem(day);
      }
      tempList[day].addAppointment(appointment);
    }

    for (const key of Object.keys(tempList)) {
      list.push(tempList[key]);
    }
    return list;
  }
}
