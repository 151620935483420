import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ApiService, NotificheService } from '@core/services';
import { FullInspection } from '@shared/models/full-form.interfaces';

@Component({
  selector: 'app-ispezione-preview',
  templateUrl: './ispezione-preview.component.html',
  styleUrls: ['./ispezione-preview.component.scss'],
})
export class IspezionePreviewComponent {
  protected fullInspection: FullInspection;
  protected loadingPdf = true;
  protected pdfSrc: string;
  constructor(
    public dialogRef: MatDialogRef<IspezionePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiService,
    private notifiche: NotificheService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.fullInspection = data.params.fullIspection;
    this.loadPdf();
  }

  protected editInspection(): void {
    this.router.navigate([
      '/appointments/coming',
      this.fullInspection.id_appuntamento,
      'compile-full-form',
    ]);
    this.dialog.closeAll();
    this.data.observer.next(true);
    this.dialogRef.close();
  }

  protected cancel(): void {
    this.data.observer.next(false);
    this.dialogRef.close();
  }

  private loadPdf(): void {
    this.api.sendEsito('/anteprima_isp_pdf', this.fullInspection).subscribe({
      next: (data): any => {
        this.pdfSrc = data.data.pdflink;
        this.loadingPdf = false;
      },
      error: (error): any => {
        this.notifiche.errore('Non posso aprire il pdf');
        this.loadingPdf = false;
        this.cancel();
      },
    });
  }
}
