import { Injectable } from '@angular/core';
import { ModaleClass, ModaleModel } from '../modali-classes';
import { MatDialog } from '@angular/material';
import { ModalSignatureComponent } from './modal-signature.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalSignatureService extends ModaleClass implements ModaleModel {
  public id = 'signature';

  constructor(public dialog: MatDialog) {
    super(dialog);
  }

  open(params?: any): Observable<any> {
    return new Observable((observer) => {
      // this.init();
      this.openBox(ModalSignatureComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        data: {
          observer: observer,
          options: params,
          modaleService: this.modaliService,
        },
      });
      this.dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }
}
