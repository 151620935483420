import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { HomeComponent } from './home.component';
import { AuthGuard } from '../../_guards/auth.guard';


import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';


import { FuseSidebarModule } from '@fuse/components';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { MessageItemComponent } from './message-item/message-item.component';
import { HomeService } from './home.service';
import { SwipeToRefreshModule } from 'ngx-swipe-to-refresh';
import { SharedModule } from '@shared/shared.module';


const routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    resolve: {
      home: HomeService
    }
  }
];

@NgModule({
  declarations: [HomeComponent, MessageItemComponent],
  imports: [
    RouterModule.forChild(routes),

    TranslateModule,

    FuseSharedModule,
    FuseSidebarModule,
    FuseWidgetModule,

    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRippleModule,
    MatCardModule,
    MatTooltipModule,
    MatMenuModule,
    SharedModule

  ],
  exports: [
    HomeComponent
  ]
})
export class HomeModule { }
