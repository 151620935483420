/**
 * Standard Enum class.
 */
export class StandardEnum {
  constructor(public value: string, public label: string) {}

  toJSON() {
    return this.value;
  }
}

/**
 * List of StandardEnum for a collection.
 */
export class ListEnum {
  /**
   * List of StandardEnum.
   */
  protected list: StandardEnum[] = [];

  constructor() {}

  /**
   * Fill the list from a json object.
   * @param json
   *  Json object to parse.
   * @returns
   *  The current ListEnum.
   */
  fillFromJson(json: any): ListEnum {
    this.list = [];
    for (const key in json) {
      this.list.push(
        new StandardEnum(key, json[key] ? json[key].toString() : '')
      );
    }
    return this;
  }

  /**
   * Get the list of enums.
   *
   * @returns
   *   List of enums.
   */
  getList(): StandardEnum[] {
    return this.list;
  }

  /**
   *  Get the label of an enum.
   * @param value
   *   Value of the enum.
   * @returns
   *   Label of the enum.
   */
  getLabel(value: string): string {
    const item = this.getItem(value);
    return item ? item.label : '';
  }

  /**
   * Get a specific enum from its value.
   *
   * @param value
   *   Value of the enum
   * @returns
   *  The enum or null if not found.
   */
  getItem(value: string): StandardEnum | null {
    return this.list.find((element) => element.value === value);
  }
}

export class ListEnumByClass {
  private classes: { [key: string]: ListEnum } = {};

  get getAll(): { [key: string]: ListEnum } {
    return this.classes;
  }

  getFromClass(classOfEnum: string): ListEnum | null {
    return this.classes.hasOwnProperty(classOfEnum)
      ? this.classes[classOfEnum]
      : null;
  }

  clear() {
    this.classes = {};
  }

  addClassEnumFromJson(classOfEnum: string, json: any[]) {
    const enums = new ListEnum();
    enums
      .getList()
      .push(
        ...json.map(
          (element) =>
            new StandardEnum(element['id'].toString(), element['descrizione'])
        )
      );
    this.classes[classOfEnum] = enums;
  }
}
