import { Injectable, NgZone } from '@angular/core';
import { AppointmentsTableService } from './tables';
import { Observable } from 'rxjs';
import { DatabaseStoreService } from './database.store.service';
import { CombustibiliTableService } from './tables/combustibili.table.service';
import { FluidiTableService } from './tables/fluidi.table.service';
import {TipiGeneratoreTableService} from './tables/tipi.generatori.table.service';
import { SchedeTableService } from './tables/schede.table.service';
import { StatiTableService } from './tables/stati.table.service';
import { take } from 'rxjs/operators';
import { StatiIspezioniTableService } from './tables/stati.ispezioni.table.service';
import { NotificheService } from '@core/services';
declare let webWorkers: any;

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(
    public appointments: AppointmentsTableService,
    public combustibili: CombustibiliTableService,
    public fluidi: FluidiTableService,
    public schede: SchedeTableService,
    public stati: StatiTableService,
    public stati_ispezioni: StatiIspezioniTableService,
    public tipi_generatori: TipiGeneratoreTableService,
    private store: DatabaseStoreService,
    private _zone: NgZone,
    private notifiche : NotificheService
  ) {
    this.appointments.changedData$.subscribe((_) => this.store.saveInStore());
    webWorkers.onMessage('ispezioni-upload', (event) => {
      debugger;
      if (!event.data.message) {
        return;
      }
      if (event.data.message === 'ispezioni-updated') {
        this._zone.run(() => {
          this.restore(true).toPromise();
        });
      }
      if (event.data.message === 'ispezioni-updated-file') {
        this._zone.run(() => {
          this.restore(true).toPromise();
          notifiche.notifica("File caricato con successo");
        });
      }

    });
  }

  restore(forceNetwork?: boolean): Observable<void> {
    return this.store.restore(forceNetwork);
  }
}
