import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { NotificheService } from '@core/services/notifiche.service';
import { LoaderService } from '@core/services/loader.service';
import { WebsocketService } from '@core/services/websocket.service';
import { Sound } from '@core/services/sound.class';

export class Status {
  in_queue: boolean;
  progressivo: string;
  position: string;
  is_current: boolean;
  last_update: number;

  constructor() {
    this.is_current = false;
    this.in_queue = false;
    this.progressivo = '';
    this.position = '';
    this.last_update = new Date().getTime();
  }

  load(data): void {
    this.is_current = data.is_current;
    if (!this.is_current) {
      this.progressivo = data.progressivo;
    } else {
      this.progressivo = data.current.progressivo;
    }
    this.in_queue = data.in_queue;
    this.position = data.position + 1;
    this.last_update = new Date().getTime();
  }
}

@Injectable({
  providedIn: 'root',
})
export class CheckinService {
  private _tasks: any[];
  private _status: Status;
  private _sound: Sound;

  onChangedTasks: BehaviorSubject<any[]>;
  onChangedStatus: BehaviorSubject<Status>;

  constructor(
    private api: ApiService,
    private notifiche: NotificheService,
    private loader: LoaderService,
    private wsclient: WebsocketService
  ) {
    this._tasks = [];
    this._status = new Status();
    this._sound = new Sound('/dist/assets/sounds/yourturn.mp3');
    this.onChangedTasks = new BehaviorSubject<any[]>([]);
    this.onChangedStatus = new BehaviorSubject<Status>(this._status);
    this.wsclient.register('tasks.queue.added').subscribe(() => {
      this.check();
    });

    this.wsclient.register('tasks.queue.updated').subscribe(() => {
      this.check();
    });
  }

  /**
   * Resolver for routing
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getTasks(), this.check()]).then(() => {
        resolve();
      }, reject);
    });
  }

  getTasks(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .sendEsito('/checkin/action/getdataforpage')
        .toPromise()
        .then(
          (data: any) => {
            this._tasks = data.tasks;
            this.onChangedTasks.next(this._tasks);
          },
          (error: any) => {
            console.log('tasks ', error);
          }
        )
        .finally(() => resolve());
    });
  }

  check(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .sendEsito('/tasks/queue/action/getstatus')
        .toPromise()
        .then(
          (data: any) => {
            this._status.load(data);
            this.onChangedStatus.next(this._status);
            resolve();
            if (this._status.is_current) {
              this._sound.play();
              if (window.navigator) {
                window.navigator.vibrate([
                  100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30,
                  100, 30, 100,
                ]);
              }
            }
          },
          (error: any) => {
            console.log('error on status check in', error);
          }
        )
        .finally(resolve);
    });
  }

  checkin(idtask: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loader.show();
      this.api
        .sendEsito('/tasks/queue/action/add', { idtask: idtask })
        .toPromise()
        .then(
          () => {
            this.notifiche.notifica('Check-in effettuato');
            resolve();
          },
          (error: any) => {
            this.notifiche.errore(error);
            reject();
          }
        )
        .finally(() => this.loader.hide());
    });
  }
}
