import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Appointment } from '@shared/models';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsListService {
  private _appointments$: BehaviorSubject<Appointment[]> = new BehaviorSubject<
    Appointment[]
  >(null);
  appointments$ = this._appointments$.asObservable();

  constructor(private api: ApiService) {}

  checkIfNeedUpdate(): Observable<void> {
    if (
      !this._appointments$.value ||
      (this._appointments$.value && this._appointments$.value.length === 0)
    ) {
      return this.update(true);
    }
    return of(null);
  }

  update(silent: boolean = false): Observable<void> {
    return this.api.send('/appointmentList', null, silent).pipe(
      tap((data) => {
        this._appointments$.next(data.response as Appointment[]);
      })
    );
  }
}
