import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormToolsService {
  markFormFieldsDirty(form: FormGroup): void {
    for (const controlKey of Object.keys(form.controls)) {
      if (form.controls[controlKey] instanceof FormGroup) {
        this.markFormFieldsDirty(form.controls[controlKey] as FormGroup);
        continue;
      }
      form.controls[controlKey].markAsDirty();
      form.controls[controlKey].markAsTouched();
      form.controls[controlKey].updateValueAndValidity({
        emitEvent: false,
        onlySelf: true,
      });
    }
  }

  parseInt(value: string): number {
    return parseInt(value, 10);
  }

  fieldIsInvalid(form: FormGroup, fieldName: string): boolean {
    const control = form.get(fieldName);
    return control.invalid && control.dirty && control.touched;
  }

  fieldHasError(
    form: FormGroup,
    fieldName: string,
    typeError: string
  ): boolean {
    const control = form.get(fieldName);
    if (!control) {
      return false;
    }
    return (
      control.errors &&
      control.errors[typeError] &&
      control.dirty &&
      control.touched
    );
  }

  onlyOneCheckBox(form: FormGroup, event: any, textFieldControl?: string) {
    if (!event.checked) {
      return;
    }
    for (const control of Object.keys(form.controls)) {
      if (control !== event.source.name) {
        form.get(control).setValue(control === textFieldControl ? null : false);
      }
    }
  }

  convertMomentDateToString(date: any): string {
    if (date && typeof date !== 'string' && date.format) {
      return date.format('YYYY-MM-DD');
    }
    return date;
  }
}
