import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsListService } from '../core/services/appointments-list.service';
import { SettingsService } from '../core/services/settings.service';
import { CombustibilePipe } from './pipes/combustibile.pipe';
import { AutomatedFormModule } from './components/automated-form/automated-form.module';
import { StatoPipe } from './pipes/stato.pipe';
import { IconStatoPipe } from './pipes/icon-stato.pipe';
import { FilterStringPipe } from './pipes/filter-string.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    CombustibilePipe,
    StatoPipe,
    IconStatoPipe,
    FilterStringPipe,
    FilterPipe,
    SignaturePadComponent,
  ],
  imports: [
    CommonModule,
    AutomatedFormModule,
    MatButtonModule,
    FlexLayoutModule,
  ],
  providers: [AppointmentsListService, SettingsService],
  exports: [
    CombustibilePipe,
    AutomatedFormModule,
    StatoPipe,
    IconStatoPipe,
    FilterStringPipe,
    FilterPipe,
    SignaturePadComponent,
  ],
})
export class SharedModule {}
