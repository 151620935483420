import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProvinceComuniService {
  private province: string[] = [];
  private comuni: { [key: string]: string[] } = {};
  private indirizzi: { [key: string]: string[] } = {};

  constructor(private api: ApiService) {}

  getProvince(): Observable<string[]> {
    if (this.province.length > 0) {
      return of(this.province);
    }
    return this.api.send('/province').pipe(
      map((response: any) => {
        if (!response.data) {
          return;
        }
        this.province = response.data.sort((a: string, b: string) =>
          a.localeCompare(b)
        );
        return this.province;
      })
    );
  }

  getComuni(provincia: string): Observable<string[]> {
    if (!provincia) {
      return of([]);
    }
    if (this.comuni.hasOwnProperty(provincia)) {
      return of(this.comuni[provincia]);
    }
    return this.api.send('/comuni', { prov: provincia }).pipe(
      map((response: any) => {
        if (!response.data) {
          return;
        }
        this.comuni[provincia] = response.data.sort((a: string, b: string) =>
          a.localeCompare(b)
        );
        return this.comuni[provincia];
      }),
      catchError((error) => {
        console.log(
          `Error on fetching comuni for provincia ${provincia}`,
          error
        );
        return of([]);
      })
    );
  }

  getIndirizzi(comune: string): Observable<string[]> {
    if (!comune) {
      return of([]);
    }
    if (this.indirizzi.hasOwnProperty(comune)) {
      return of(this.indirizzi[comune]);
    }
    return this.api.send('/viario', { comune_id: comune, indirizzo: '' }).pipe(
      map((response: any) => {
        if (!response.indirizzi) {
          return;
        }
        this.indirizzi[comune] = response.indirizzi.sort(
          (a: string, b: string) => a.localeCompare(b)
        );
        return this.indirizzi[comune];
      }),
      catchError((error) => {
        console.log(`Error on fetching indirizzi for comune ${comune}`, error);
        return of([]);
      })
    );
  }
}
