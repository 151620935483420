import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatabaseService } from './database.service';
import { DatabaseStoreService } from './database.store.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[ DatabaseService]
})
export class DatabaseModule { }
