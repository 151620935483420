import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { MessagingService } from '@core/services/messaging-service.service';
import { BookingSidebar, BookingService } from '@core/services/booking.service';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

@Component({
  selector: 'quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponent implements OnInit {
  date: Date;
  events: any[];
  notifies: any[];
  settings: any;
  booking: BookingSidebar;

  /**
   * Constructor
   */
  constructor(
    private messagingService: MessagingService,
    private bookingService: BookingService,
    private router: Router,
    private fuseSidebarService: FuseSidebarService
  ) {
    this.notifies = [];
    // Set the defaults
    this.date = new Date();
    this.settings = {
      notify: true,
      cloud: false,
      retro: true,
    };
  }

  ngOnInit(): void {
    this.messagingService.onChangeNotification.subscribe((notifies: any[]) => {
      this.notifies = notifies;
    });
    this.bookingService.onSideBarChanged.subscribe(
      (sidebar) => (this.booking = sidebar)
    );
  }

  navigate(url): void {
    this.router.navigateByUrl(url);
    this.fuseSidebarService.getSidebar('quickPanel').close();
  }
}
