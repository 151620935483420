import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  Output,
  Input,
  EventEmitter,
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {
  UploaderService,
  UploadFileClass,
  UploadStatus,
} from '@core/services/uploader.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'multi-file-uploader',
  templateUrl: './multi-file-uploader.component.html',
  styleUrls: ['./multi-file-uploader.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class MultiFileUploaderComponent implements OnInit {
  @ViewChild('file', { static: false }) file;

  @Output() progressChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() fileChanged: EventEmitter<UploadFileClass[]> = new EventEmitter<
    UploadFileClass[]
  >();
  @Output() inProgress: boolean;

  attachments: Map<String, UploadFileClass> = new Map();

  constructor(private uploader: UploaderService) {
    this.attachments = new Map();
  }

  ngOnInit(): void {
    this.attachments = new Map();
  }

  addFiles($event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.file.nativeElement.click();
  }

  onFilesAdded(): void {
    this.inProgress = true;
    this.progressChanged.emit(true);
    const filesInput: { [key: string]: File } = this.file.nativeElement.files;
    const files: Map<string, File> = new Map();
    for (const key in filesInput) {
      if (!isNaN(parseInt(key, 10))) {
        const time =
          new Date().getTime() + ' - ' + Math.floor(Math.random() * 1000);
        files.set(filesInput[key].name + '-' + time, filesInput[key]);
      }
    }

    const progress = this.uploader.uploadMulti(files);
    const forFinish = [];
    console.log(progress);
    for (const key of Object.keys(progress)) {
      forFinish.push(progress[key]);
      progress[key].subscribe((file) => {
        this.attachments.set(key, file);
        if (file.error) {
          setTimeout(() => {
            this.removeAttachment(key);
          }, 5000);
        }
        // console.log(key, file);
      });
    }

    forkJoin(Array.from(forFinish)).subscribe((end) => {
      this.inProgress = false;
      this.progressChanged.emit(true);
    });
  }

  removeAttachment(key: string, $event?: any): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (!this.attachments.has(key)) {
      return;
    }
    this.attachments.delete(key);
  }

  getFiles(): UploadFileClass[] {
    return Array.from(this.attachments.values()).filter(
      (item) => item.completed
    );
  }
}
