import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckinService } from './checkin.service';
import { AuthGuard } from 'app/_guards/auth.guard';
import { CheckinComponent } from './checkin/checkin.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
const routes = [
  {
    path: 'checkin',
    component: CheckinComponent,
    canActivate: [AuthGuard],
    resolve: {
      home: CheckinService
    }
  }
];

@NgModule({
  declarations: [CheckinComponent],
  imports: [
    RouterModule.forChild(routes),

    CommonModule,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,

    FuseSharedModule,
    FuseWidgetModule
  ],
  providers: [
    CheckinService
  ],
  exports: [RouterModule]
})
export class CheckinModule { }
