import { Injectable } from '@angular/core';
import {ModaleClass, ModaleModel} from '../modali-classes';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TermsConditionComponent } from './terms-condition.component';


@Injectable({
  providedIn: 'root'
})
export class TermsConditionService extends ModaleClass implements ModaleModel{

  public id = 'terms-condition';


  constructor(public dialog: MatDialog ) {
    super(dialog);
  }

  open(params?: any): Observable<any> {
    return Observable.create((observer) => {

      // this.init();
      this.openBox(TermsConditionComponent, { width: '90%' });
      this.dialogRef.afterClosed().subscribe(
          () => {
          }
      );
      observer.complete();
    });
  }


}
