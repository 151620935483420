import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { CheckinService, Status } from '../checkin.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class CheckinComponent implements OnInit, OnDestroy {

  tasks: any[];
  status: Status;
  idtask: string;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private checkinService: CheckinService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.idtask = '-1';
    this.checkinService.onChangedTasks
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (tasks) => {
          this.tasks = tasks;
        });

    this.checkinService.onChangedStatus
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (status) => {
          this.status = status;
        });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();    
  }

  /**
   *  public function 
   */
  checkin(): void {
    this.checkinService.checkin(this.idtask).then(
      () => {
        this.idtask = '-1';
      }
      );
  }
  

}
