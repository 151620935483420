import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  Form,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService, FormToolsService, NotificheService } from '@core/services';
import { FullInspectionToSend } from '@core/services/full-inspection-sender.service';
import { IModaliService } from '../modali-service.interface';

export enum TypeOfSignature {
  responsible,
  inspector,
}
@Component({
  selector: 'app-request-otp',
  templateUrl: './request-otp.component.html',
  styleUrls: ['./request-otp.component.scss'],
})
export class RequestOtpComponent {
  protected formOtp: FormGroup;
  protected formPhoneNumber: FormGroup;
  protected fullInspection: FullInspectionToSend;
  protected sendingOtp = false;
  protected currentOtp: string;
  protected currentInspectorOtp: string;
  protected modaliService: IModaliService;
  protected responsibleSignature: string;
  protected inspectorSignature: string;
  protected typeOfSignature = TypeOfSignature;

  constructor(
    public dialogRef: MatDialogRef<RequestOtpComponent>,
    protected formToolsService: FormToolsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notifiche: NotificheService // private sender: FullInspectionSenderService,
  ) {
    this.fullInspection = data.params.fullIspection;
    this.buildForm();
  }

  cancel(): void {
    this.data.observer.next(false);
    this.dialogRef.close();
  }

  openPdfPreview(): void {
    console.log(this.modaliService);
    this.modaliService.open('ispezione-preview', this.fullInspection);
  }

  sendOtp(): void {
    console.log(this.fullInspection);
    if (this.formPhoneNumber.invalid) {
      this.formToolsService.markFormFieldsDirty(this.formPhoneNumber);
      return;
    }
    this.sendingOtp = true;
    this.api
      .sendEsito('/generate_otp', {
        phone_number: this.formPhoneNumber.value.phoneNumber,
        type: 'ispezioni',
      })
      .subscribe({
        next: (res) => {
          if (res.status === 'success') {
            this.currentOtp = res.otp;
            this.currentInspectorOtp = res.otp_isp;
            if (res.debug == true) {
              console.log('res otp:' + res.debug);
              this.formOtp.setValue({
                otp: res.otp,
                otpInspector: res.otp_isp,
              });
            }
          }
        },
        error: (err) => {
          this.notifiche.errore(
            'Si è verifucato un errore durante la generazione del codice OTP'
          );
        },
      });
  }

  sendIspezione(): void {
    if (this.formOtp.invalid) {
      this.formToolsService.markFormFieldsDirty(this.formOtp);
      return;
    }

    this.api.sendEsito('/aggiungi_ispezione', this.fullInspection).subscribe({
      next: () => {
        this.notifiche.notifica('Ispezione inviata con successo');
        this.data.observer.next(true);
        this.dialogRef.close();
      },
      error: (err) => {
        console.log(err);
        this.notifiche.errore('Si è verificato un errore: ' + err);
      },
    });
  }

  sendIspezioneWithSignature(): void {
    if (!this.inspectorSignature) {
      this.notifiche.errore("Inserire la firma dell'ispettore");
      return;
    }
    if (!this.responsibleSignature) {
      this.notifiche.errore('Inserire la firma del responsabile');
      return;
    }
    this.fullInspection.inspectorSignature = this.inspectorSignature;
    this.fullInspection.responsibleSignature = this.responsibleSignature;
    const ispezione = {
      ...this.fullInspection,
      signature_responsible: this.responsibleSignature,
      signature_inspector: this.inspectorSignature,
    };
    this.api.sendEsito('/aggiungi_ispezione', ispezione).subscribe({
      next: () => {
        this.notifiche.notifica('Ispezione inviata con successo');
        this.data.observer.next(true);
        this.dialogRef.close();
      },
      error: (err) => {
        console.log(err);
        this.notifiche.errore('Si è verificato un errore: ' + err);
      },
    });
  }

  protected openSignature(typeOfSignature: TypeOfSignature) {
    this.modaliService
      .openObservable('signature', {
        title:
          typeOfSignature === TypeOfSignature.inspector
            ? 'Firma ispettore'
            : 'Firma responsabile',
      })
      .subscribe({
        next: (signature) => {
          if (!signature) {
            return;
          }
          switch (typeOfSignature) {
            case TypeOfSignature.responsible:
              this.responsibleSignature = signature;
              break;
            case TypeOfSignature.inspector:
              this.inspectorSignature = signature;
              break;
          }
        },
      });
  }

  protected removeSignature(typeOfSignature: TypeOfSignature) {
    switch (typeOfSignature) {
      case TypeOfSignature.responsible:
        delete this.responsibleSignature;
        break;
      case TypeOfSignature.inspector:
        delete this.inspectorSignature;
        break;
    }
  }

  private buildForm(): void {
    this.formPhoneNumber = this.formBuilder.group({
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{9,11}$')],
      ],
    });
    this.formOtp = this.formBuilder.group({
      otp: ['', [Validators.required, this.checkOtp()]],
      otpInspector: ['', [Validators.required, this.checkOtpInspector()]],
    });
  }

  private checkOtp(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      return value !== this.currentOtp.toString() ? { checkOtp: true } : null;
    };
  }
  private checkOtpInspector(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      return value !== this.currentInspectorOtp.toString()
        ? { checkOtp: true }
        : null;
    };
  }
}
