import {Appointment} from '@shared/models';
import { DatePipe } from '@angular/common';


export class HomeItem{
    day: string;
    dayNumber: string;
    month: string;
    appointments: Appointment[];

    constructor(day: string){
        const date = new DatePipe('it');

        this.day = day;        
        this.dayNumber = date.transform(day,'dd');
        this.month = date.transform(day,'MMM');
        this.appointments = [];
    }

    addAppointment(appointment: Appointment): void{
        this.appointments.push(appointment);
    }
}
