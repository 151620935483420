import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterString',
})
export class FilterStringPipe implements PipeTransform {
  transform(values: string[], ...args: any[]): any {
    if (!args[0]) {
      return values;
    }
    return values.filter((value) =>
      value.toLowerCase().includes(args[0].toLowerCase())
    );
  }
}
