import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { BaseComponent } from '../base.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IModaliService } from './modali-service.interface';
declare var CallBack: any;

export interface ModaleModel {
  id: string;
  modaliService: IModaliService;
  open(params?: any): Observable<any>;
}

export class ModaleClass extends BaseComponent {
  box: any;
  observer: Subscriber<any>;
  dialogRef: MatDialogRef<any>;
  modaliService: IModaliService;

  constructor(public dialog: MatDialog) {
    super();
  }

  openBox(component: any, config?: any): MatDialogRef<any, any> {
    // $(this.box).fadeIn();
    this.dialogRef = this.dialog.open(component, config);
    this.dialogRef.componentInstance.modaliService = this.modaliService;
    return this.dialogRef;
  }

  closeBox(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.closeBox();
    this.observer.complete();
  }
}
