import { NgModule, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModaliService } from './modali-service.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '../../@fuse/shared.module';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { ChangeAvatarComponent } from './change-avatar/change-avatar.component';
import { ImageCropperModule } from 'ngx-img-cropper';
import { RequestOtpComponent } from './request-otp/request-otp.component';
import { IspezionePreviewComponent } from './ispezione-preview/ispezione-preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ModalSignatureComponent } from './modal-signature/modal-signature.component';
import { SharedModule } from '@shared/shared.module';
import { MatDividerModule, MatTab, MatTabsModule } from '@angular/material';

@NgModule({
  declarations: [
    ChangePasswordComponent,
    TermsConditionComponent,
    ConfirmComponent,
    ChangeAvatarComponent,
    RequestOtpComponent,
    IspezionePreviewComponent,
    ModalSignatureComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    NgxMatSelectSearchModule,
    FuseSharedModule,
    ImageCropperModule,
    NgxExtendedPdfViewerModule,
    SharedModule,
    MatTabsModule,
    MatDividerModule,
  ],
  entryComponents: [
    ChangePasswordComponent,
    TermsConditionComponent,
    ConfirmComponent,
    ChangeAvatarComponent,
    RequestOtpComponent,
    IspezionePreviewComponent,
    ModalSignatureComponent,
  ],
  providers: [ModaliService],
})
export class ModaliModule {}
