import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModaleClass, ModaleModel } from '../modali-classes';
import { Observable } from 'rxjs';
import { IspezionePreviewComponent } from './ispezione-preview.component';

@Injectable({
  providedIn: 'root',
})
export class IspezionePreviewService
  extends ModaleClass
  implements ModaleModel
{
  public id = 'ispezione-preview';

  constructor(public dialog: MatDialog) {
    super(dialog);
  }

  open(fullIspection: any): Observable<any> {
    return new Observable((observer) => {
      this.openBox(IspezionePreviewComponent, {
        width: '90vw',
        height: '90vh',
        data: { observer: observer, params: { fullIspection } },
      });
      this.dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }
}
