import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { NotificheService } from '@core/services';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements AfterViewInit {
  @ViewChild('canvas', { static: false }) canvasEl!: ElementRef;
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() receiveSignature: EventEmitter<string> = new EventEmitter<string>();
  private signaturePad!: SignaturePad;

  constructor(private notifiche: NotificheService) {}

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
    this.resizeCanvas();
  }

  clear(): void {
    this.cancel.emit();
  }

  save(): void {
    if (this.signaturePad.isEmpty()) {
      this.notifiche.errore('Firma mancante');
      return;
    }
    this.receiveSignature.emit(this.signaturePad.toDataURL());
  }

  private resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvasEl.nativeElement.width =
      this.canvasEl.nativeElement.offsetWidth * ratio;
    this.canvasEl.nativeElement.height =
      this.canvasEl.nativeElement.offsetHeight * ratio;
    this.canvasEl.nativeElement.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }
}
