import { Injectable } from '@angular/core';
import { FullInspection } from '@shared/models/full-form.interfaces';
import { ModaliService } from 'app/modali/modali-service.service';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface FullInspectionToSend {
  inspection: FullInspection;
  inspectorSignature?: string;
  responsibleSignature?: string;
  date: Date;
  appuntamentoId: number;
  sent: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FullInspectionSenderService {
  readonly fullInspectionsToSend$: BehaviorSubject<FullInspectionToSend[]> =
    new BehaviorSubject<FullInspectionToSend[]>([]);
  readonly fullInspectionsToSendPending$: BehaviorSubject<
    FullInspectionToSend[]
  > = new BehaviorSubject<FullInspectionToSend[]>([]);
  protected fullInspectionsToSend: FullInspectionToSend[];
  private readonly keyStorage = 'fullInspectionToSend';

  constructor(private modalService: ModaliService) {
    this.loadFromStore();
  }

  getPedingInspections(): FullInspectionToSend[] {
    return this.fullInspectionsToSend.filter((item) => !item.sent) || [];
  }

  getInspection(appuntamentoId: number): FullInspection | null {
    const item = this.fullInspectionsToSend.find(
      (item) => item.appuntamentoId === appuntamentoId
    );
    return item ? item.inspection : null;
  }

  saveInspection(
    fullInspectionToSend: FullInspection,
    appuntamentoId: number
  ): Observable<any> {
    this.add(fullInspectionToSend, appuntamentoId);
    return of(true);
  }

  sendInspection(fullInspectionToSend: FullInspectionToSend) {
    const inspection = { ...fullInspectionToSend.inspection };
    const indexToDelete =
      this.fullInspectionsToSend.indexOf(fullInspectionToSend);
    if (!inspection.dati.nome_delegato) {
      inspection.dati.nome_delegato = '';
    }
    this.modalService
      .openObservable('request-otp', inspection)
      .subscribe((sended) => {
        if (indexToDelete !== -1 && sended) {
          this.fullInspectionsToSend[indexToDelete].sent = true;
          this.saveToStore();
        }
      });
  }

  add(fullInspection: FullInspection, appuntamentoId: number): void {
    const existentId = this.fullInspectionsToSend.findIndex(
      (item) => item.appuntamentoId === appuntamentoId
    );
    if (existentId === -1) {
      const date = new Date();
      this.fullInspectionsToSend.push({
        inspection: fullInspection,
        date,
        appuntamentoId,
        sent: false,
      });
    } else {
      this.fullInspectionsToSend[existentId].inspection = fullInspection;
    }
    this.saveToStore();
  }

  loadFromStore(): void {
    const fullInspectionToSend = localStorage.getItem(this.keyStorage);
    this.fullInspectionsToSend = JSON.parse(fullInspectionToSend || '[]');
    this.fullInspectionsToSend$.next(this.fullInspectionsToSend);
    this.fullInspectionsToSendPending$.next(this.getPedingInspections());
  }

  saveToStore(): void {
    this.fullInspectionsToSend$.next(this.fullInspectionsToSend);
    this.fullInspectionsToSendPending$.next(this.getPedingInspections());
    localStorage.setItem(
      this.keyStorage,
      JSON.stringify(this.fullInspectionsToSend)
    );
  }

  cleanStore(): void {
    this.fullInspectionsToSend = [];
    this.saveToStore();
  }
}
