import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ProfileService, Profile } from './profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificheService } from '@core/services/notifiche.service';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { RegisterComponent } from '../register/register.component';
import { ModaliService } from 'app/modali/modali-service.service';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class ProfileComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;
  private modifiedProfilo: Profile;
  private _firstCheck: boolean;

  profile: Profile;
  inEdit: boolean;
  profileForm: FormGroup;

  constructor(
    private service: ProfileService,
    private notifiche: NotificheService,
    private _formBuilder: FormBuilder,
    private modali: ModaliService
  ) {
    this._unsubscribeAll = new Subject<any>();
  }

  ngOnInit(): void {
    this.inEdit = false;
    this._firstCheck = false;
    this.profileForm = this._formBuilder.group({
      nome: ['', Validators.required],
      cognome: ['', Validators.required],
      sesso: [''],
      data_nascita: [''],
      email: ['', [Validators.required, Validators.email]],
      mobile: [
        '',
        [Validators.required, RegisterComponent.phoneNUmberValidator],
      ],
      sede: ['', this.zipCodeValidator()],
    });

    this.service.onProfileChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((profile: Profile) => {
        if (profile.avatar === '' || profile.avatar === null) {
          profile.avatar =
            'https://ui-avatars.com/api/?rounded=true&background=03a6e9&color=fff&name=' +
            encodeURI(profile.nome + ' ' + profile.cognome);
        }
        this.profile = profile;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  changeAvatar(): void {
    this.modali.openObservable('change-avatar').subscribe((img: any) => {
      this.service.saveAvatar(img);
    });
  }

  launchEdit(): void {
    this.profileForm.controls['nome'].setValue(this.profile.nome);
    this.profileForm.controls['cognome'].setValue(this.profile.cognome);
    this.profileForm.controls['sesso'].setValue(this.profile.sesso);
    this.profileForm.controls['data_nascita'].setValue(
      this.profile.data_nascita
    );
    this.profileForm.controls['email'].setValue(this.profile.email);
    this.profileForm.controls['mobile'].setValue(this.profile.mobile);
    this.profileForm.controls['sede'].setValue(this.profile.formatted_sede);
    this._firstCheck = true;
    this.inEdit = true;
    this.modifiedProfilo = new Profile();
  }

  save(): void {
    this.profileForm.get('sede').updateValueAndValidity();
    console.log(this.profileForm);
    if (!this.profileForm.valid) {
      this.notifiche.errore('Ci sono degli errori, controlla i dati inseriti');
      return;
    }

    this.modifiedProfilo.nome = this.profileForm.controls['nome'].value;
    this.modifiedProfilo.cognome = this.profileForm.controls['cognome'].value;
    this.modifiedProfilo.sesso = this.profileForm.controls['sesso'].value;
    this.modifiedProfilo.data_nascita =
      this.profileForm.controls['data_nascita'].value;
    this.modifiedProfilo.email = this.profileForm.controls['email'].value;
    this.modifiedProfilo.mobile = this.profileForm.controls['mobile'].value;
    this.service.saveProfile(this.modifiedProfilo).then(() => this.closeEdit());
    // console.log(this.modifiedProfilo);
  }

  closeEdit(): void {
    this.inEdit = false;
  }

  getNumber(obj): void {
    this.profileForm.controls['mobile'].setValue(obj);
  }

  telInputObject(obj): void {
    RegisterComponent.numberObject = obj;
  }

  onChangeAddress($event: any): void {
    this._firstCheck = false;
    this.modifiedProfilo.formatted_sede = '';
    this.modifiedProfilo.sede_numero_civico = '';
    this.modifiedProfilo.sede_indirizzo = '';
    this.modifiedProfilo.sede_comune = '';
    this.modifiedProfilo.sede_provincia = '';
    this.modifiedProfilo.sede_provincia_short = '';
    this.modifiedProfilo.sede_regione = '';
    this.modifiedProfilo.sede_nazione = '';
    this.modifiedProfilo.sede_cap = '';
    this.modifiedProfilo.sede_lat = null;
    this.modifiedProfilo.sede_lng = null;
  }

  onAutocompleteSelected($event: any): void {
    console.log($event);
    this.modifiedProfilo.formatted_sede = $event.formatted_address;
    $event.address_components.forEach((item) => {
      switch (true) {
        case item.types.indexOf('street_number') !== -1:
          this.modifiedProfilo.sede_numero_civico = item.long_name;
          break;
        case item.types.indexOf('route') !== -1:
          this.modifiedProfilo.sede_indirizzo = item.long_name;
          break;
        case item.types.indexOf('administrative_area_level_3') !== -1:
        case item.types.indexOf('locality') !== -1:
          this.modifiedProfilo.sede_comune = item.long_name;
          break;
        case item.types.indexOf('administrative_area_level_2') !== -1:
          this.modifiedProfilo.sede_provincia = item.long_name;
          this.modifiedProfilo.sede_provincia_short = item.short_name;
          break;
        case item.types.indexOf('administrative_area_level_1') !== -1:
          this.modifiedProfilo.sede_regione = item.short_name;
          break;
        case item.types.indexOf('country') !== -1:
          this.modifiedProfilo.sede_nazione = item.long_name;
          break;
        case item.types.indexOf('postal_code') !== -1:
          this.modifiedProfilo.sede_cap = item.short_name;
          break;
      }
    });
    this.profileForm.get('sede').updateValueAndValidity();
  }

  onLocationSelected($event: any): void {
    this.modifiedProfilo.sede_lat = $event.latitude;
    this.modifiedProfilo.sede_lng = $event.longitude;
  }

  public zipCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (this._firstCheck) {
        return null;
      }
      if (!this.inEdit) {
        return null;
      }

      if (control.value !== null || control.value !== '') {
        if (!this.modifiedProfilo.sede_cap) {
          return { validateAddress: true };
        }

        if (this.modifiedProfilo.sede_cap === '') {
          return { validateAddress: true };
        }
      }
      return null;
    };
  }
}
