export enum TypeIndex {
  Normal,
  Composited,
}

export interface Indexes {
  [indexName: string]: Index;
}

export interface Index {
  [key: string]: number[];
}
