import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Scheda, DatabaseService, Field, Validator } from '@core/database';
import { map } from 'rxjs/operators';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NotificheService } from '@core/services/notifiche.service';

@Component({
  selector: 'automated-form',
  templateUrl: './automated-form.component.html',
  styleUrls: ['./automated-form.component.scss'],
})
export class AutomatedFormComponent implements OnInit {
  @Input() schedaId: number;
  @Input() data: any;
  @Output() confirmed: EventEmitter<any> = new EventEmitter<any>();
  scheda$: Observable<Scheda>;
  formScheda: FormGroup;

  constructor(
    private database: DatabaseService,
    private notifiche: NotificheService
  ) {}

  ngOnInit(): void {
    this.scheda$ = this.database.schede.selectByPrimaryKey(this.schedaId).pipe(
      map((scheda) => {
        this.elaborateForm(scheda);
        return scheda;
      })
    );
  }

 
  attach_file() : void {
    return
  }

  confirmForm(): void {
    if (!this.formScheda.valid) {
      this.notifiche.errore('controlla i valori inseriti');
      return;
    }

    this.confirmed.emit(this.formScheda.value);
  }

  private elaborateForm(scheda: Scheda): void {
    const controls: any = {};

    for (const field of scheda.fields) {
      controls[field.DataModel] = this.convertField(field);
    }
    this.formScheda = new FormGroup(controls);
  }

  private convertField(field: Field): FormControl {
    return new FormControl(
      this.getValue(field.DataModel),
      this.getValidators(field.validators)
    );
  }

  getValue(fieldName: string): any {
    const path = fieldName.split('.');
    let elem: any = this.data;
    console.log(this.data);
    for (const name of path) {
      if (!elem[name]) {
        return '';
      }
      elem = elem[name];
    }

    return elem;
  }

  getValidators(validators: Validator[]): any[] {
    if (!validators) {
      return [];
    }

    const resValids: any[] = [];

    for (const validator of validators) {
      switch (validator.type) {
        case 'required':
          resValids.push(Validators.required);
          break;
      }
    }

    return resValids;
  }
}
