import { Observable, of, BehaviorSubject, from, throwError } from 'rxjs';
import { mergeMap, switchMap, finalize, map } from 'rxjs/operators';
import { Indexes, IndexDeclaration, IndexFunctions, Index } from './indexing';
import { IndexTypes } from './indexing/index-types';
import { IndexSearch } from './indexing/index-search';
import { SelectItem } from '../models';

interface DictionaryIndex {
  [key: string]: IndexDeclaration;
}

export abstract class BaseTable<T> {
  protected table: T[] = [];
  protected indexes: Indexes = {};
  protected indexDefinition: DictionaryIndex = {};
  protected primaryIndex: IndexDeclaration;
  protected table$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  protected fieldSelectItemDisplay: string;
  protected fieldSelectItemValue: string;
  changedData$: Observable<void> = this.table$.pipe(
    map((elem) => {
      return;
    })
  );
  constructor() {
    this.initialize();
    this.initIndexes();
  }

  abstract initialize(): void;

  //#region

  propagate(): void {
    this.table$.next(this.table);
  }

  //#endregion

  //#region protected function

  protected addIndex(index: IndexDeclaration): void {
    if (this.indexDefinition[index.name] !== undefined) {
      throw new Error('Index just defined');
    }
    this.indexDefinition[index.name] = index;
  }

  protected *getIndexes() {
    for (const indexName of Object.keys(this.indexDefinition)) {
      yield this.indexDefinition[indexName];
    }
  }

  protected initIndexes(): void {
    this.indexes[this.primaryIndex.name] = {};
    for (const index of this.getIndexes()) {
      this.indexes[index.name] = {};
    }
  }

  protected indexing(position: number, item: T): void {
    const primaryIndexKey = this.primaryIndex.generateKey(item);
    this.addIndexPosition(this.primaryIndex.name, primaryIndexKey, position);

    for (const index of this.getIndexes()) {
      if (index.type === IndexTypes.normal) {
        const keyNameValue = index.generateKey(item);
        this.addIndexPosition(index.name, keyNameValue, position);
      } else {
        for (const keyName of index.generateKeyArray(item)) {
          this.addIndexPosition(index.name, keyName, position);
        }
      }
    }
  }

  protected addIndexPosition(
    indexName: string,
    keyName: string,
    position: number
  ): void {
    if (!this.indexes[indexName][keyName]) {
      this.indexes[indexName][keyName] = [];
    }
    this.indexes[indexName][keyName].push(position);
  }

  protected reIndexing(): void {
    this.initIndexes();
    const tableLength = this.table.length;
    for (let i = 0; i < tableLength; i++) {
      this.indexing(i, this.table[i]);
    }
  }

  protected getPositionByIndex(indexName: string, ...value: any[]): number[] {
    return this.indexes[indexName][IndexFunctions.generateKeyByValue(value)];
  }

  //#endregion

  //#region public crud function
  insert(element: T, propagateEvent: boolean = true): Observable<void> {
    return new Observable((observer) => {
      // console.log(element);
      if (element == null) {
        observer.complete();
        return;
      }
      this.table.push(element);
      this.indexing(this.table.length - 1, element);
      if (propagateEvent) {
        this.propagate();
      }
      observer.complete();
    });
  }

  insertBulk(elements: T[]): Observable<void> {
    return from<T[]>(elements).pipe(
      switchMap((element) => this.insert(element, false)),
      finalize(() => {
        this.propagate();
      })
    );
  }

  update(element: T): Observable<void> {
    return new Observable((observer) => {
      if (element == null) {
        observer.complete();
        return;
      }
      const index = this.getPositionByIndex(
        this.primaryIndex.name,
        ...this.primaryIndex.getValues(element)
      );
      if (index === null) {
        return;
      }
      this.table[index[0]] = element;
      this.reIndexing();
      this.propagate();
      observer.complete();
    });
  }

  delete(element: T): Observable<void> {
    return new Observable((observer) => {
      this.removeByIndex(
        this.primaryIndex.name,
        ...this.primaryIndex.getValues(element)
      );
      observer.complete();
    });
  }

  truncate(propagateEvent: boolean = true): Observable<void> {
    return new Observable((observer) => {
      this.table = [];
      this.initIndexes();
      if (propagateEvent) {
        this.propagate();
      }
      observer.complete();
    });
  }

  removeByIndex(indexName: string, ...value: any[]): Observable<void> {
    return new Observable((observer) => {
      const indexes = this.getPositionByIndex(indexName, value);
      if (indexes === undefined) {
        return null;
      }
      // sort indexes by value desc so i start to delete from the bigger index to lower index
      indexes.sort((a, b) => b - a);
      indexes.forEach((index) => this.table.splice(index, 1));
      this.reIndexing();
      this.propagate();
      observer.complete();
    });
  }

  selectByIndexes(...search: IndexSearch[]): Observable<T[]> {
    return this.table$.pipe(
      map((table) => {
        let positions: number[] = [];
        let first = true;
        for (const index of search) {
          const indexes = this.getPositionByIndex(
            index.indexName,
            index.values
          );
          if (indexes === undefined) {
            return [];
          }
          if (first) {
            positions = indexes;
            first = false;
            continue;
          }
          positions = positions.filter((position) =>
            indexes.includes(position)
          );
        }
        return positions.map((position) => table[position]);
      })
    );
  }

  selectByIndex(indexName: string, ...value: any[]): Observable<T[]> {
    return this.table$.pipe(
      map((table) => {
        const indexes = this.getPositionByIndex(indexName, value);
        const result: T[] = [];
        if (indexes !== undefined) {
          indexes.forEach((index) => result.push(table[index]));
        }
        return result;
      })
    );
  }

  selectOneByIndex(indexName: string, ...value: any[]): Observable<T> {
    return this.table$.pipe(
      map((table) => {
        const indexes = this.getPositionByIndex(indexName, value);
        if (indexes === undefined) {
          throwError('record not found');
        } else {
          return table[indexes[0]];
        }
      })
    );
  }

  selectByPrimaryKey(value: any): Observable<T> {
    return this.selectOneByIndex(this.primaryIndex.name, value);
  }

  selectByPrimaryKeys(values: any[]): Observable<T[]> {
    return new Observable((observer) => {
      const result: T[] = [];
      of(values)
        .pipe(mergeMap((value) => this.selectByPrimaryKey(value)))
        .subscribe(
          (item) => result.push(item),
          (error) => console.log(error),
          () => observer.complete()
        );
    });
  }

  selectAll(): Observable<T[]> {
    return this.table$;
  }

  getValuesForSelect(): Observable<SelectItem[]> {
    return this.table$.pipe(
      map((elements) =>
        elements.map((element) => {
          const selectItem: SelectItem = {
            value: element[this.fieldSelectItemValue],
            display: element[this.fieldSelectItemDisplay],
          };
          return selectItem;
        })
      )
    );
  }

  //#endregion

  //#region export and import data

  toJSON(): T[] {
    return this.table;
  }

  loadData(data: any[]): void {
    this.truncate(false);
    this.table = data as T[];
    this.reIndexing();
    this.propagate();
  }

  //#endregion
}
