import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [    
    {
                
        id       : 'applications',
        title    : 'Helpdesk',
        translate: 'NAV.HELPDESK',
        type     : 'group',
        children : [
            {
                id       : 'home',
                title    : 'Home',
                translate: 'NAV.HOME.TITLE',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/home'
                
            },
            // {
            //     id       : 'products',
            //     title    : 'Prodotti',
            //     translate: 'NAV.PRODUCTS.TITLE',
            //     type     : 'item',
            //     icon     : 'shop',
            //     url      : '/products',                
            // },
            // {
            //     id       : 'inassistance',
            //     title    : 'In assistenza',
            //     translate: 'NAV.INASSISTANCE.TITLE',
            //     type     : 'collapsable',
            //     icon     : 'build',
            //     // url      : '/inassistance',
            //     badge    : {
            //         title    : '',                    
            //         bg       : '#F44336',
            //         fg       : '#FFFFFF',
            //         hidden   : true  
            //     },
            //     children : [
            //         {
            //             id   : 'pending',
            //             title: 'Prodotti in riparazione',
            //             translate: 'NAV.INASSISTANCE.PENDING',
            //             type : 'item',
            //             url  : '/inassistance/pending'
            //         },
            //         {
            //             id   : 'history',
            //             title: 'Storico',
            //             translate: 'NAV.INASSISTANCE.HISTORY',
            //             type : 'item',
            //             url  : '/inassistance/history'
            //         }
            //     ]
            // },            
            // {
            //     id       : 'support',
            //     title    : 'Supporto',
            //     translate: 'NAV.SUPPORT.TITLE',
            //     type     : 'collapsable',
            //     icon     : 'help_outline',
            //     // url      : '/support',
            //     badge    : {
            //         title    : '',                    
            //         bg       : '#ec0c8e',
            //         fg       : '#FFFFFF',
            //         hidden   : true  
            //     },
            //     children : [
            //         {
            //             id   : 'support.new',
            //             title: 'Apri ticket',
            //             translate: 'NAV.SUPPORT.NEW',
            //             type : 'item',
            //             url  : '/support/new'
            //         },                    
            //         {
            //             id   : 'support.pending',
            //             title: 'Ticket aperti',
            //             translate: 'NAV.SUPPORT.PENDING',
            //             type : 'item',
            //             url  : '/support/pending'
            //         },                    
            //         {
            //             id   : 'support.history',
            //             title: 'Storico',
            //             translate: 'NAV.SUPPORT.HISTORY',
            //             type : 'item',
            //             url  : '/support/history'
            //         }
            //     ]
            // },            
            // {
            //     id       : 'appointments',
            //     title    : 'Appuntamenti',
            //     translate: 'NAV.APPOINTMENTS.TITLE',
            //     type     : 'collapsable',
            //     icon     : 'calendar_today',
            //     /*url      : '/appointments',*/
            //     // badge    : {
            //     //     title    : '25',                    
            //     //     bg       : '#ff6300',
            //     //     fg       : '#FFFFFF',
            //     //     hidden   : false  
            //     // },
            //     children : [
            //         // {
            //         //     id   : 'appointments.new',
            //         //     title: 'Richiedi appuntamento',
            //         //     translate: 'NAV.APPOINTMENTS.NEW',
            //         //     type : 'item',
            //         //     url  : '/appointments/new'
            //         // },
            //         {
            //             id   : 'appointments.coming',
            //             title: 'Prossimi appuntamenti',
            //             translate: 'NAV.APPOINTMENTS.COMING',
            //             type : 'item',                        
            //             url  : '/appointments/coming'
            //         },
            //         // {
            //         //     id   : 'appointments.pending',
            //         //     title: 'In approvazione',
            //         //     translate: 'NAV.APPOINTMENTS.PENDING',
            //         //     type : 'item',
            //         //     url  : '/appointments/pending'
            //         // },                    
            //         {
            //             id   : 'appointments.history',
            //             title: 'storico',
            //             translate: 'NAV.APPOINTMENTS.HISTORY',
            //             type : 'item',
            //             url  : '/appointments/history'
            //         }
            //     ]
            // },
            // {
            //     id       : 'messages',
            //     title    : 'Messaggi',
            //     translate: 'NAV.MESSAGES.TITLE',
            //     type     : 'item',
            //     icon     : 'email',
            //     url      : '/messages',
            //     badge    : {
            //         title    : '25',                    
            //         bg       : '#09d261',
            //         fg       : '#FFFFFF',
            //         hidden   : true  
            //     }
            // },            
            // {
            //     id       : 'coda_shop',
            //     title    : 'Check-in shop',
            //     translate: 'NAV.SHOP_QUEUE.TITLE',
            //     type     : 'item',
            //     icon     : 'filter_1',
            //     url      : '/checkin',
            //     badge    : {
            //         title    : '25',                    
            //         bg       : '#F44336',
            //         fg       : '#FFFFFF',
            //         hidden   : true  
            //     }
            // },            
        ]
    }
];
