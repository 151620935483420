import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filter: string, field?: string): any {
    if (!filter || filter === '') {
      return items;
    }
    filter = filter.toLowerCase();
    const fields: string[] = !field ? Object.keys(items[0]) : [field];
    return items.filter((item: any) => {
      return fields.some((field) => {
        console.log(item[field], filter);
        return (
          item[field] &&
          typeof 'string' &&
          item[field] &&
          item[field].toLowerCase().indexOf(filter) !== -1
        );
      });
    });
  }
}
