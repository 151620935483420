import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModaleClass, ModaleModel } from '../modali-classes';
import { ConfirmComponent } from './confirm.component';
import { Observable } from 'rxjs';

export interface ConfirmOptions {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmService extends ModaleClass implements ModaleModel {
  public id = 'confirm';

  constructor(public dialog: MatDialog) {
    super(dialog);
  }

  open(params: ConfirmOptions): Observable<any> {
    return new Observable((observer) => {
      // this.init();
      this.openBox(ConfirmComponent, {
        width: 'auto',
        data: { observer: observer, options: params },
      });
      this.dialogRef.afterClosed().subscribe(() => {
        observer.complete();
      });
    });
  }
}
