import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ModaleModel } from './modali-classes';
import { ChangePasswordService } from './change-password/change-password.service';
import { TermsConditionService } from './terms-condition/terms-condition.service';
import { ConfirmService } from './confirm/confirm.service';
import { ChangeAvatarService } from './change-avatar/change-avatar.service';
import { RequestOtpService } from './request-otp/request-otp.service';
import { IModaliService } from './modali-service.interface';
import { IspezionePreviewService } from './ispezione-preview/ispezione-preview.service';
import { ModalSignatureService } from './modal-signature/modal-signature.service';
declare var CallBack: any;

@Injectable({
  providedIn: 'root',
})
export class ModaliService implements IModaliService {
  private modals: ModaleModel[] = [];

  constructor(
    private changePasswordService: ChangePasswordService,
    private termsCondistionService: TermsConditionService,
    private confirmService: ConfirmService,
    private changeAvatarService: ChangeAvatarService,
    private requestOptService: RequestOtpService,
    private ispezionePreviewService: IspezionePreviewService,
    private modalSignatureService: ModalSignatureService
  ) {
    this.add(this.changePasswordService);
    this.add(this.termsCondistionService);
    this.add(this.confirmService);
    this.add(this.changeAvatarService);
    this.add(this.requestOptService);
    this.add(this.ispezionePreviewService);
    this.add(this.modalSignatureService);
  }

  add(modale: ModaleModel): void {
    modale.modaliService = this;
    this.modals.push(modale);
  }

  openObservable(id: string, params?: any): Observable<any> {
    const modale: ModaleModel = this.modals.find((value) => value.id === id);
    if (modale === undefined) {
      return Observable.throw('Modale not found');
    }
    return modale.open(params);
  }

  open(id: string, params?: any): void {
    const modale: ModaleModel = this.modals.find((value) => value.id === id);
    if (modale === undefined) {
      throw 'Modale not found';
    }
    modale.open(params).subscribe();
  }
}
