import {
  Component,
  OnDestroy,
  OnInit,
  ReflectiveInjector,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ModaliService } from '../../../modali/modali-service.service';
import { ApiService } from '../../../core/services/api.service';
import { NotificheService } from '../../../core/services/notifiche.service';
import { LoaderService } from '../../../core/services/loader.service';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class RegisterComponent implements OnInit, OnDestroy {
  static numberObject: any;
  registerForm: FormGroup;

  // Private
  private _unsubscribeAll: Subject<any>;

  static phoneNUmberValidator(fc: FormControl): ValidationErrors | null {
    if (fc.value === '') {
      return null;
    }

    if (!RegisterComponent.numberObject) {
      return null;
    }
    if (RegisterComponent.numberObject.intlTelInput('isValidNumber')) {
      return null;
    }
    return { numberNotValid: true };
  }

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private modali: ModaliService,
    private api: ApiService,
    private notifiche: NotificheService,
    private _spinner: LoaderService,
    private authService: AuthService,
    private router: Router
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: [
        '',
        [Validators.required, RegisterComponent.phoneNUmberValidator],
      ],
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
      term: [true, Validators.required],
    });

    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    /* this.registerForm.get('password').valueChanges
             .pipe(takeUntil(this._unsubscribeAll))
             .subscribe(() => {
                 this.registerForm.get('passwordConfirm').updateValueAndValidity();
             });*/
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openTerms(): void {
    this.modali.open('terms-condition');
  }

  telInputObject(obj): void {
    RegisterComponent.numberObject = obj;
  }

  sendRequest(): void {
    if (this.registerForm.invalid) {
      this.notifiche.notifica('Controlla i valori inseriti');
      return;
    }

    this._spinner.show();
    const req = {
      nome: this.registerForm.value.name,
      cognome: this.registerForm.value.surname,
      mobile: RegisterComponent.numberObject.intlTelInput('getNumber'),
      email: this.registerForm.value.email,
      password: this.registerForm.value.password,
      conf_password: this.registerForm.value.passwordConfirm,
    };

    this.api
      .sendEsito('/account/action/create', req)
      .toPromise()
      .then(
        (data: any) => {
          this.notifiche.notifica(data.message);
          this.authService.storeLoginData(data.login, '');
          this.router.navigate(['/']);
        },
        (error: any) => {
          this.notifiche.errore(error);
        }
      )
      .finally(() => this._spinner.hide());
  }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
