// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  firebase: {
    apiKey: 'AIzaSyBqIpF7SQBzcoRlk5M_p4M-2OgVcOsqNfU',
    authDomain: 'helpdesk-bnet.firebaseapp.com',
    databaseURL: 'https://helpdesk-bnet.firebaseio.com',
    projectId: 'helpdesk-bnet',
    storageBucket: 'helpdesk-bnet.appspot.com',
    messagingSenderId: '864549116670',
    appId: '1:864549116670:web:5a1d9599a92be27e',
  },
  websocket: {
    port: 9810,
    svkwb: 'jgst',
    server: 'dev-sunisland.bnetsrl.it',
    protocol: 'wss://',
  },
  logged: false,
  api: {
    url: window['config'].api_url['stage'],
    token: '',
  },
  google: {
    apiKey: 'AIzaSyBQXtplGe9hfkw-X5bRL8YTUdy5lGdzmuA',
  },
  indexedDB: {
    currentVersion: 10,
    dbName: 'ispezioni-catasto',
    storeName: {
      ispezioni: 'ispezioni',
    },
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
