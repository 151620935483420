import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { environment } from 'environments/environment';
@Injectable()
export class TokenAuthInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.api.token !== '' && environment.api.token !== null) {
      request = request.clone({
        setHeaders: {
          'x-auth': environment.api.token,
        },
      });
    }
    return next.handle(request);
  }
}
