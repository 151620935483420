import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@core/services/api.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { NotificheService } from '@core/services/notifiche.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { LoaderService } from '../../core/services/loader.service';

// import { ChangeDetectionStrategy } from '@angular/compiler/src/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    public api: ApiService,
    private _formBuilder: FormBuilder,
    private _spinner: LoaderService,
    private notifiche: NotificheService,
    private auth: AuthService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      currentPassword: ['', Validators.required],
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
    });
    this.resetPasswordForm
      .get('password')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this._spinner.show();
    this.auth
      .changePassword(
        this.resetPasswordForm.value.currentPassword,
        this.resetPasswordForm.value.password
      )
      .then(
        () => {
          this.dialogRef.close();
          this.notifiche.notifica('Password cambiata correttamente');
        },
        (error: any) => {
          this.notifiche.errore(error);
        }
      )
      .finally(() => this._spinner.hide());
  }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };
};
