import { Injectable } from '@angular/core';
import { ListEnum, ListEnumByClass } from '@shared/models/enum.model';
import { ApiService } from '@core/services/api.service';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export enum EnumTypes {
  tipiResponsabile = '/enums_tipianagrafica',
  modelloinserimento = '/enumsModelloinserimento',
  prescrizioni = '/enumsPrescrizioni_isp',
  osservazioni = '/enumsOsservazioni_isp',
}

export enum EnumTypesByClass {
  combustibili = '/enumsCombustibili',
  tipiGeneratori = '/enumsTipigeneratori',
}

@Injectable({
  providedIn: 'root',
})
export class EnumService {
  listYesNo: ListEnum = new ListEnum().fillFromJson({ a: 'Si', b: 'No' });
  listYesNoNc: ListEnum = new ListEnum().fillFromJson({
    a: 'Si',
    b: 'No',
    c: 'Nc',
  });

  listYesNoNa: ListEnum = new ListEnum().fillFromJson({
    a: 'Si',
    b: 'No',
    c: 'Na',
  });
  private enums: { [key: string]: ListEnum } = {};
  private enumsByClass: { [key: string]: ListEnumByClass } = {};
  private observables: { [key: string]: Observable<any> } = {};
  constructor(private api: ApiService) {
    // this.loadEnums().subscribe(() => {});
  }

  getEnum(enumType: EnumTypes): Observable<ListEnum> {
    if (this.enums[enumType]) {
      return of(this.enums[enumType]);
    }
    return this.loadEnum(enumType).pipe(map(() => this.enums[enumType]));
  }

  getEnumByClass(enumType: EnumTypesByClass): Observable<ListEnumByClass> {
    if (this.enumsByClass[enumType]) {
      return of(this.enumsByClass[enumType]);
    }
    return this.observables[enumType]
      ? this.observables[enumType]
      : (this.observables[enumType] = this.loadEnum(
          EnumTypes.modelloinserimento
        ).pipe(
          switchMap(() => this.loadEnumsByClass()),
          map(() => this.enumsByClass[enumType]),
          tap(() => (this.observables[enumType] = null))
        ));
  }

  loadEnums(): Observable<any> {
    const key = 'enums';
    if (this.observables[key]) {
      return this.observables[key];
    }
    const enumsObeservables: Observable<any>[] = [];
    for (let enumType in EnumTypes) {
      enumsObeservables.push(this.loadEnum(EnumTypes[enumType]));
    }
    return (this.observables[key] = forkJoin(enumsObeservables).pipe(
      switchMap(() => this.loadEnumsByClass()),
      tap(() => (this.observables[key] = null))
    ));
  }

  loadEnum(enumType: string): Observable<any> {
    if (this.observables[enumType]) {
      return this.observables[enumType];
    }
    return (this.observables[enumType] = this.api.sendEsito(enumType).pipe(
      map((response) => {
        this.observables[enumType] = null;
        this.loadEnumFromResponse(enumType, response.data);
      })
    ));
  }

  private loadEnumsByClass(): Observable<any> {
    const key = 'enumsByClass';
    const enumsObeservables: Observable<any>[] = [];
    return this.observables[key]
      ? this.observables[key]
      : (this.observables['enumsByClass'] = this.getEnum(
          EnumTypes.modelloinserimento
        ).pipe(
          switchMap((modelloinserimento) => {
            for (let enumType in EnumTypesByClass) {
              for (let classId of modelloinserimento.getList()) {
                enumsObeservables.push(
                  this.loadEnumByClass(
                    classId.value,
                    EnumTypesByClass[enumType]
                  )
                );
              }
            }
            return forkJoin(enumsObeservables);
          }),
          tap(() => (this.observables['enumsByClass'] = null))
        ));
  }

  loadEnumByClass(classId: string, enumType: string): Observable<any> {
    const key = `${classId}-${enumType}`;
    return this.observables[key]
      ? this.observables[key]
      : (this.observables[key] = this.api
          .sendEsito(enumType, { classe: classId })
          .pipe(
            map((response) => {
              if (!this.enumsByClass[enumType]) {
                this.enumsByClass[enumType] = new ListEnumByClass();
              }
              this.enumsByClass[enumType].addClassEnumFromJson(
                classId,
                response.data
              );
            }),
            tap(() => (this.observables[key] = null))
          ));
  }

  private loadEnumFromResponse(enumType: string, enumsFromApi: any): ListEnum {
    return (this.enums[enumType] = new ListEnum().fillFromJson(enumsFromApi));
  }
}
