import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export enum Stores {
  ispezioni = 'ispezioni',
}

const configuration = {
  currentVersion: 10,
  dbName: 'ispezioni-catasto',
  stores: {
    [Stores.ispezioni]: {
      name: Stores.ispezioni,
      createOptions: { autoIncrement: false, keyPath: 'idapp' },
      indexes: [
        {
          field: 'uploaded',
          options: { unique: false },
        },
      ],
    },
  },
};

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  private db: IDBDatabase;

  constructor() {
    const request = indexedDB.open(
      configuration.dbName,
      configuration.currentVersion
    );

    request.onsuccess = (event: any) => {
      this.db = event.target.result;
    };

    request.onerror = (event: any) => {
      console.log('IndexedDB error: ' + event.target.errorCode);
    };

    request.onupgradeneeded = (event: any) => {
      let db = event.target.result;
      for (const dbs of Object.values(Stores)) {
        const store = db.createObjectStore(
          dbs,
          configuration.stores[dbs].createOptions
        );
        for (const index of configuration.stores[dbs].indexes) {
          store.createIndex(index.field, index.field, index.options);
        }
      }
    };
  }

  create(storeName: Stores, value: any): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let request = this.db
        .transaction([storeName], 'readwrite')
        .objectStore(storeName)
        .add(value);

      request.onsuccess = (event: any) => {
        resolve(true);
      };

      request.onerror = (event: any) => {
        console.error(event);
        console.log('Write failed: ' + event.target.errorCode);
        reject(event.target);
      };
    });
  }

  read(storeName: Stores, key: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const request = this.getObjectStore(storeName).get(key);

      request.onerror = (event: any) => {
        reject('Unable to retrieve data from database!');
      };

      request.onsuccess = (event: any) => {
        resolve(request.result);
      };
    });
  }

  update(storeName: Stores, key: any, value: any) {
    return new Promise<boolean>((resolve, reject) => {
      const request = this.getObjectStore(storeName).put(value, key);

      request.onsuccess = (event: any) => {
        resolve(true);
      };

      request.onerror = (event: any) => {
        console.error(event);
        console.log('Update failed: ' + event.target.errorCode);
        reject(event.target);
      };
    });
  }

  delete(storeName: Stores, key: any) {
    return new Promise<boolean>((resolve, reject) => {
      const request = this.getObjectStore(storeName).delete(key);

      request.onsuccess = (event: any) => {
        resolve(true);
      };

      request.onerror = (event: any) => {
        console.error(event);
        console.log('Delete failed: ' + event.target.errorCode);
        reject(event.target);
      };
    });
  }

  clear(storeName: Stores): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const request = this.getObjectStore(storeName).clear();

      request.onsuccess = (event: any) => {
        resolve(true);
      };

      request.onerror = (event: any) => {
        console.error(event);
        console.log('Delete failed: ' + event.target.errorCode);
        reject(event.target);
      };
    });
  }

  protected getObjectStore(
    storeName: Stores,
    mode: IDBTransactionMode = 'readwrite'
  ): IDBObjectStore {
    return this.db.transaction([storeName], mode).objectStore(storeName);
  }
}
