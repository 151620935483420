import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './main/pages/pages.module';
import { AuthService } from './core/services/auth.service';
import { ApiService } from './core/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './core/services/messaging-service.service';
import { ModaliModule } from './modali/modali.module';
import { ModaliService } from './modali/modali-service.service';
import { LoaderService } from './core/services/loader.service';
import { HomeModule } from './main/home/home.module';
import { WeatherService } from './core/services/weather.service';
import { UploaderService } from './core/services/uploader.service';
import { MultiFileUploaderModule } from './shared/components/multi-file-uploader/multi-file-uploader.module';
import { WebsocketService } from './core/services/websocket.service';
import { BookingService } from './core/services/booking.service';
import { NavigationNotificationService } from './core/services/navigation-notification.service';
import { ErrorService } from './main/pages/error/error.service';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { AgmCoreModule } from '@agm/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { httpInterceptorProviders } from './core/interceptors';
import { SharedModule } from '@shared/shared.module';

registerLocaleData(localeIt, 'it');

const googleMapsParams = {
  apiKey: environment.google.apiKey,
  libraries: ['places'],
  language: 'it',
  // region: 'IT'
  // region: 'DE'
};

export function authInit(authService: AuthService): any {
  return () => authService.checkBootLogin();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PagesModule,
    HomeModule,

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,

    SharedModule,
    // FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    NgxSpinnerModule,
    // services
    // ServicesModule
    // AngularFireAuthModule,
    // AngularFireMessagingModule,
    // AngularFireModule.initializeApp(environment.firebase),

    ModaliModule,
    MultiFileUploaderModule,
    AgmCoreModule.forRoot(googleMapsParams),
    MatGoogleMapsAutocompleteModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    ApiService,
    CookieService,
    MessagingService,
    ModaliService,
    LoaderService,
    WeatherService,
    UploaderService,
    WebsocketService,
    BookingService,
    NavigationNotificationService,
    ErrorService,
    GoogleMapsAPIWrapper,
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: authInit,
      multi: true,
      deps: [AuthService],
    },
    { provide: LOCALE_ID, useValue: 'it' },
  ],
  entryComponents: [
    // ChangePasswordComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
