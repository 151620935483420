import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmartqueueComponent } from './smartqueue.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { FuseSidebarModule, FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { SmartqueueService } from './smartqueue.service';

const routes = [
  {
      path     : 'smartqueue',
      component: SmartqueueComponent      
  }
];

@NgModule({
  declarations: [SmartqueueComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    FuseSharedModule,
    FuseSidebarModule,
    FuseWidgetModule,

    MatButtonModule,
    MatDividerModule,    
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatRippleModule

  ],
  providers: [ 
    SmartqueueService
  ]
})
export class SmartqueueModule { }
