import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '@core/services/auth.service';
import { NotificheService } from '@core/services/notifiche.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../../core/services/loader.service';
import { error } from 'console';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private notifiche: NotificheService,
    private router: Router
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required]], // Validators.email
      password: ['', Validators.required],
      catasto: ['', Validators.required],
    });
  }

  login(): void {
    this.authService
      .login(
        this.loginForm.value.email,
        this.loginForm.value.password,
        this.loginForm.value.catasto
      )
      .then(
        () => this.router.navigate(['/']),
        (error) => {
          this.notifiche.errore(
            error || 'Si è verificato un errore durante il login'
          );
        }
      );
  }
}
