import { Injectable, NgZone } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { NotificheService } from './notifiche.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { WebsocketService } from './websocket.service';
import * as firebase from 'firebase';
import { environment } from 'environments/environment.prod';
import { messaging } from 'firebase/app';


declare let config: any;

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  _token: String = '';
  toRegister = false;
  onChangeNotification: BehaviorSubject<any[]>;
  notifies: any[];
  messaging: messaging.Messaging;



  constructor(

    // private angularFireAuth: AngularFireAuth,
    // private angularFireMessaging: AngularFireMessaging,
    private api: ApiService,
    private auth: AuthService,
    private zone: NgZone,
    private notifiche: NotificheService,
    private location: Location,
    private router: Router,
    private _fuseNavigationService: FuseNavigationService,
    private wsclient: WebsocketService
  ) {

    this.onChangeNotification = new BehaviorSubject([]);
    firebase.initializeApp(environment.firebase);
  
    this.messaging = firebase.messaging();

    this.messaging.onMessage(this.onMessage.bind(this));
    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {

    //     // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     // _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //     console.log(_messaging);
    //   }
    //  }
    // );

    this.auth.currentUser.subscribe(
      (user: any) => {
        if (!this.auth.isLogged) {
          return;
        }
        this.registerToken();
        this.fetchToRead();
      });

    this.wsclient.register('incomingMessage')
      .subscribe(() => this.fetchToRead());
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(token: string): void {
    // we can change this function to request our backend service
    // console.log(token);
    if (this.auth.isLogged) {
      this.registerToken();
    }
    // this.angularFireAuth.authState.pipe(take(1)).subscribe(
    //   () => {
    //     const data = {};
    //     data[userId] = token;
    //     this.angularFireDB.object('fcmTokens/').update(data);
    //   });
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(): void {

    this.messaging.requestPermission()
      .then(() => this.messaging.getToken())
      .then((token) =>
        this.zone.run(() => {
          this._token = token;
          this.updateToken(token);
        })
      );

    // this.angularFireMessaging.requestPermission
    //   .subscribe(
    //     () => { console.log('Permission granted!'); },
    //     (error) => { console.error(error); },  
    //   );


    // this.angularFireMessaging.requestToken.subscribe(
    //   (token) => {
    //     console.log(token);
    //     this._token = token;
    //     this.updateToken(token);
    //   },
    //   (err) => {
    //     console.error('Unable to get permission to notify.', err);
    //   }
    // );
    // this.angularFireMessaging.tokenChanges.subscribe(
    //   (token) => {
    //     console.log("token changes", token);
    //     Error.stackTraceLimit = undefined;
    //     console.trace('ecco');
    //     this._token = token;
    //     this.updateToken(token);
    //   },
    //   (err) => {
    //     console.error('Unable to get permission to notify.', err);
    //   }
    // );
  }

  /**
   * hook method when new notification received in foreground
   */

  onMessage(payload): void {
    // console.log('messaggio', payload);
    this.zone.run(() => {
      let action = 'OK';
      // console.log('new message received. ', payload);
      if (payload.data && payload.data.action === 'openurl') {
        action = 'APRI';
        this.notifiche.notifica(payload.data.title, action)
          .onAction()
          .subscribe(() => {
            if (payload.notification.click_action.indexOf(window.location.origin) === -1) {
              window.location.href = payload.notification.click_action;
            } else {
              this.router.navigateByUrl(payload.notification.click_action.replace(window.location.origin, ''));
            }
          });

      } else {
        this.notifiche.notifica(payload.notification.title);
      }
      this.currentMessage.next(payload);
    });
  }


  receiveMessage(): void {
    // this.angularFireMessaging.messages.subscribe(
    //   (payload: any) => {
    //     let action = 'OK';
    //     console.log('new message received. ', payload);
    //     if (payload.data && payload.data.action === 'openurl') {
    //       action = 'APRI';
    //       this.notifiche.notifica(payload.data.title, action)
    //         .onAction()
    //         .subscribe(() => {
    //           if (payload.notification.click_action.indexOf(window.location.origin) === -1) {
    //             window.location.href = payload.notification.click_action;
    //           } else {
    //             this.router.navigateByUrl(payload.notification.click_action.replace(window.location.origin, ''));
    //           }
    //         });

    //     } else {
    //       this.notifiche.notifica(payload.notification.title);
    //     }
    //     this.currentMessage.next(payload);
    //   });
  }

  public get token(): String {
    return this._token;
  }

  public registerToken(): void {
    if (this._token === '') {
      this.toRegister = true;
      return;
    }
    this.api.sendEsito('/messaging/action/registertoken', { token: this._token }).toPromise().then();

  }

  fetchToRead(): void {
    if (!this.auth.isLogged) { return; }
    // this.api.sendEsito('/messaggi/notifiche/action/gettoread').toPromise().then(
    //   (resp: any) => {
    //     this.notifies = resp.list;
    //     this.onChangeNotification.next(this.notifies);
    //     if (resp.count > 0) {
    //       this._fuseNavigationService.updateNavigationItem('messages', {
    //         badge: {
    //           title: resp.count,
    //           hidden: false
    //         }
    //       });
    //     } else {
    //       this._fuseNavigationService.updateNavigationItem('messages', {
    //         badge: {
    //           title: '',
    //           hidden: true
    //         }
    //       });
    //     }
    //   },
    //   (error: any) => {
    //     console.log('error on fetch notify to read', error);
    //   }
    // );
  }



}
