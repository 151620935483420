import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'modal-signature',
  templateUrl: './modal-signature.component.html',
  styleUrls: ['./modal-signature.component.scss'],
})
export class ModalSignatureComponent {
  protected title = 'Conferma';
  constructor(
    public dialogRef: MatDialogRef<ModalSignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.options.title) {
      this.title = this.data.options.title;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  receiveSignature(signature: string): void {
    this.data.observer.next(signature);
    this.dialogRef.close();
  }
}
