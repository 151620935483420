export const environment = {
  production: true,
  hmr: false,
  firebase: {
    apiKey: 'AIzaSyBqIpF7SQBzcoRlk5M_p4M-2OgVcOsqNfU',
    authDomain: 'helpdesk-bnet.firebaseapp.com',
    databaseURL: 'https://helpdesk-bnet.firebaseio.com',
    projectId: 'helpdesk-bnet',
    storageBucket: 'helpdesk-bnet.appspot.com',
    messagingSenderId: '864549116670',
    appId: '1:864549116670:web:5a1d9599a92be27e',
  },
  websocket: {
    port: 9810,
    svkwb: 'jgst',
    server: 'dev-sunisland.bnetsrl.it',
    protocol: 'wss://',
  },
  logged: false,
  api: {
    url: window['config'].api_url['locale'],
    token: '',
  },
  google: {
    apiKey: 'AIzaSyBQXtplGe9hfkw-X5bRL8YTUdy5lGdzmuA',
  },
};
