import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificheService } from '@core/services/notifiche.service';
import { AuthService } from '@core/services/auth.service';

export class ApiError {
  constructor(public message: string) {}
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notifiche: NotificheService, private auth: AuthService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.status) {
            if (evt.body.status !== 'success') {
              throwError(
                new HttpErrorResponse({ error: new ApiError(evt.body.error) })
              );
            }
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        console.log(error, 'error');
        console.log(req);
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Errore: ${error.error.message}`;
        } else if (error.error instanceof ApiError) {
          errorMsg = error.error.message;
        } else if (error.error.message) {
          errorMsg = `Errore: ${error.error.message}`;
        } else if (error.error.error) {
          if (error.status === 401 && req.url.indexOf('login') === -1) {
            if (window.location.pathname.indexOf('login') === -1) {
              this.auth.logout();
            }
          }
          errorMsg = `Errore: ${error.error.error}`;
        } else {
          errorMsg = `Errore id: ${error.status},  ${error.message}`;
        }
        if (!req.headers.has('silent') && !req.headers.has('not-show-error')) {
          this.notifiche.errore(errorMsg);
        }
        return throwError(errorMsg);
      })
    );
  }
}
