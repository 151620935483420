import { Injectable } from '@angular/core';
import { ObjectModel } from 'app/object-model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '@core/services/api.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ErrorService } from '../error/error.service';
import { LoaderService } from '@core/services/loader.service';
import { NotificheService } from '@core/services/notifiche.service';
import { AuthService } from '@core/services/auth.service';

export class Profile extends ObjectModel {
  nome: string;
  cognome: string;
  login: string;
  formatted_sede: string;
  sesso: string;
  data_nascita: string;
  mobile: string;
  email: string;
  avatar: string;
  sede_nazione: string;
  sede_regione: string;
  sede_provincia: string;
  sede_comune: string;
  sede_indirizzo: string;
  sede_cap: string;
  sede_lat: number;
  sede_lng: number;
  sede_provincia_short: string;
  sede_numero_civico: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private _profile: Profile;
  onProfileChanged: BehaviorSubject<Profile>;
  constructor(
    private api: ApiService,
    private error: ErrorService,
    private loader: LoaderService,
    private notifiche: NotificheService,
    private authService: AuthService
  ) {
    this.onProfileChanged = new BehaviorSubject<Profile>(new Profile());
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getProfile()]).then(() => {
        resolve();
      }, reject);
    });
  }

  getProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .sendEsito('/account/action/getcurrent')
        .toPromise()
        .then(
          (data: any) => {
            this._profile = new Profile(data.profile);
            this.onProfileChanged.next(this._profile);
          },
          (error: any) => {
            console.log('get profile ', error);
            this.error.showError(error);
          }
        )
        .finally(() => resolve());
    });
  }

  saveProfile(profile: Profile): Promise<any> {
    this.loader.show();
    return new Promise((resolve, reject) => {
      this.api
        .sendEsito('/account/action/updatecurrent', { profile: profile })
        .toPromise()
        .then(
          (resp: any) => {
            this.notifiche.notifica('Profilo aggiornato con successo');
            this._profile = new Profile(resp.profile);
            this.onProfileChanged.next(this._profile);
            const user = this.authService.currentUserValue;
            user.name = this._profile.nome;
            user.surname = this._profile.cognome;
            user.email = this._profile.email;
            this.authService.currentUserValue = user;
            resolve();
          },
          (error: any) => {
            this.notifiche.errore(error);
            reject();
          }
        )
        .finally(() => this.loader.hide());
    });
  }

  saveAvatar(img: any): Promise<any> {
    this.loader.show();
    return new Promise((resolve, reject) => {
      this.api
        .sendEsito('/account/action/savemyavatar', { img: img })
        .toPromise()
        .then(
          (resp: any) => {
            this._profile.avatar = img;
            this.onProfileChanged.next(this._profile);
            const user = this.authService.currentUserValue;
            user.avatar = img;
            this.authService.currentUserValue = user;
            resolve();
          },
          (error: any) => {
            this.notifiche.errore(error);
            reject();
          }
        )
        .finally(() => this.loader.hide());
    });
  }
}
