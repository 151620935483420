import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AuthService, UserClass } from '@core/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificheService } from '@core/services/notifiche.service';
import { ModaliService } from 'app/modali/modali-service.service';
import { MessagingService } from '@core/services/messaging-service.service';
import { DatabaseService } from '@core/database';
import { LoaderService } from '@core/services/loader.service';
import Dexie from 'dexie';
import { environment } from 'environments/environment';
import { IndexedDBService, Stores } from '@core/services';
import { FullInspectionSenderService } from '@core/services/full-inspection-sender.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  user: UserClass;
  hasNotify: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _authService: AuthService,
    private _spinner: NgxSpinnerService,
    private _notifiche: NotificheService,
    private _modali: ModaliService,
    private messagingService: MessagingService,
    private _database: DatabaseService,
    private loaderService: LoaderService,
    private nortificheService: NotificheService,
    private indexDBService: IndexedDBService,
    private indexIspService: FullInspectionSenderService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.languages = [
      {
        id: 'it',
        title: 'Italiano',
        flag: 'it',
      },
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
    this._authService.onChangeCurrentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        this.user = user;
      });

    this.messagingService.onChangeNotification
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifies: any[]) => {
        this.hasNotify = notifies.length > 0;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout(): void {
    this._spinner.show();
    this._authService
      .logout()
      .then(
        () => {},
        (error: any) => {
          this._notifiche.errore(error);
        }
      )
      .finally(() => {
        this._spinner.hide();
      });
  }

  changePassword(): void {
    this._modali.open('change-password');
  }

  updateData(): void {
    this._database.restore(true).toPromise();
  }

  protected async cleanCache() {
    try {
      this.loaderService.show();
      await this.indexDBService.clear(Stores.ispezioni);
      this.nortificheService.notifica('Cache pulita con successo');
    } catch (e) {
      this.nortificheService.errore('Si è verificato un errore: ' + e.message);
    }
    this.indexIspService.cleanStore();
    this.loaderService.hide();
  }
}
