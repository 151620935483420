import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error.component';
import { ErrorService } from './error.service';
import { Routes, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';

const routes: Routes = [
  {
      path     : 'error',
      component: ErrorComponent      
  }
];

@NgModule({
  declarations: [ErrorComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,

    FuseSharedModule
  ],  

})
export class ErrorModule { }
