import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';
import { WebsocketService } from './websocket.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

export class BookingSidebar {
  pending: any[];
  coming: any[];
  constructor(resp?: any) {
    if (!resp) {
      this.pending = [];
      this.coming = [];
    } else {
      this.pending = resp.pending;
      this.coming = resp.coming;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  _sidebar: BookingSidebar;
  onSideBarChanged: BehaviorSubject<BookingSidebar>;



  constructor(
    private api: ApiService,
    private auth: AuthService,
    private wsclient: WebsocketService,
    private _fuseNavigationService: FuseNavigationService
  ) {

    this.onSideBarChanged = new BehaviorSubject<BookingSidebar>(new BookingSidebar());
    this.auth.onChangeCurrentUser
      .subscribe(() => {
        if (auth.isLogged) {
          this.update();
        }
      }
      );
    this.wsclient.register('tasks.booking.updated').subscribe(() => this.update());
    this.wsclient.register('tasks.booking.added').subscribe(() => this.update());

  }

  update(): void {
    // this.api.sendEsito('/booking/action/getforsidebar')
    //   .toPromise()
    //   .then((resp) => {
    //     this.onSideBarChanged.next(new BookingSidebar(resp));
    //     if (resp.coming.length === 0 && resp.pending.length === 0) {
    //       this._fuseNavigationService.updateNavigationItem('appointments', {
    //         badge: {
    //           title: '',
    //           hidden: true
    //         }
    //       });
    //     } else {
    //       this._fuseNavigationService.updateNavigationItem('appointments', {
    //         badge: {
    //           title: resp.coming.length + '/' + resp.pending.length,
    //           hidden: false
    //         }
    //       });
    //     }
    //   });
  }
}
