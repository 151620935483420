import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';


import { LoginModule } from './main/pages/login/login.module';
import { ResetPasswordModule } from './main/pages/reset-password/reset-password.module';
import { LockModule } from './main/pages/lock/lock.module';
import { MailConfirmModule } from './main/pages/mail-confirm/mail-confirm.module';
import { RegisterModule } from './main/pages/register/register.module';
import { ForgotPasswordModule } from './main/pages/forgot-password/forgot-password.module';
import { CheckinModule } from './main/checkin/checkin.module';
import { SmartqueueModule } from './main/pages/smartqueue/smartqueue.module';
import { ErrorModule } from './main/pages/error/error.module';
import { ProfileModule } from './main/pages/profile/profile.module';




const routes: Routes = [    
    {
        path        : 'products',
        loadChildren: () => import('./main/products/products.module').then(m => m.ProductsModule)
    },
    {
        path        : 'messages',
        loadChildren: () => import('./main/messages/messages.module').then(m => m.MessagesModule)
    },
    {
        path        : 'support',
        loadChildren: () => import('./main/support/support.module').then(m => m.SupportModule)
    },    
    {
        path        : 'inassistance',
        loadChildren: () => import('./main/inassistance/inassistance.module').then(m => m.InassistanceModule)
    },    
    {
        path        : 'appointments',
        loadChildren: () => import('./main/appointments/appointments.module').then(m => m.AppointmentsModule)
    },    
    {
        path      : '**',
        redirectTo: 'home',
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        LoginModule,
        ResetPasswordModule,
        LockModule,
        MailConfirmModule,
        RegisterModule,
        ForgotPasswordModule,
        CheckinModule,
        SmartqueueModule,
        ErrorModule,
        ProfileModule        
        // ProductsModule
        
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
